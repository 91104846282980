import elementspaneloverview from "../../../../assets/images/docs/elements/overview.png";
import domview from "../../../../assets/images/docs/elements/domview.png";
import classeditor from "../../../../assets/images/docs/elements/classeditor.png";
import addclasses from "../../../../assets/images/docs/elements/classeditor-suggestions.png";
import a11ytab from "../../../../assets/images/docs/elements/a11ytab.png";
import navigator from "../../../../assets/images/docs/navigator.png";
import flexgrid from "../../../../assets/images/blogs/pp131/flexgrid.png";
import debugpanel from "../../../../assets/images/blogs/pp14/debug.png";
import layertree from "../../../../assets/images/docs/layertree.png";
import stylepanel from "../../../../assets/images/docs/elements/style.png";
import containerquerystylepanel from "../../../../assets/images/docs/containerquerystylepanel.png";
import shiftclickcolor from "../../../../assets/images/docs/elements/shiftclickcolor.mp4";
import badges from "../../../../assets/images/docs/elements/badges.png";
import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import * as React from 'react';
export default {
  elementspaneloverview,
  domview,
  classeditor,
  addclasses,
  a11ytab,
  navigator,
  flexgrid,
  debugpanel,
  layertree,
  stylepanel,
  containerquerystylepanel,
  shiftclickcolor,
  badges,
  ShortCutDisplay,
  React
};